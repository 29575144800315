import { setDocumentCookie } from '../utils/cookies';

export function setCookie(key: string, value: string, tti: number): Promise<Response> {
    const date = new Date();
    date.setTime(date.getTime() + tti);
    const expiretime = Math.floor(date.setTime(date.getTime() + tti) / 1000);
    return fetch(
        `/c/api/cookie/register/?key=${key}&value=${value}&samesite=none&expiretime=${expiretime.toString()}&httpOnly=0`
    ).then(res => {
        if (!res.ok) {
            throw new Error(res.statusText);
        }
        return res;
    });
}

// eslint-disable-next-line max-params
export const setCookieWithImageRequest = (name: string, value: string, tti?: number): void => {
    setDocumentCookie(name, '1', tti);

    const origin = (window?.location?.origin ?? '').replace('westwingnow', 'westwing');
    const isStageOrLiveOrigin = ['stage', 'westwing'].includes(origin);

    if (isStageOrLiveOrigin) {
        let expiretime = '';
        if (tti) {
            const date = new Date();
            date.setTime(date.getTime() + tti);
            expiretime = `expiretime=${Math.floor(date.setTime(date.getTime() + tti) / 1000)}&`;
        }

        const cookieSetter = new Image();

        // Request to set the cookie in club's domain
        cookieSetter.src = `${origin}/c/api/cookie/register/?key=${name}&value=${value}&${expiretime}samesite=none&httpOnly=0&responsetype=image`;
    }
};
