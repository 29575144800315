/* eslint-disable camelcase */
/* Disabled to comply with Segment tracking parameters */

const SHOP_SEGMENT_BANNER_FEATURE_NAME = 'Smart App';
export const SEGMENT_EVENT_FEATURE_VIEWED = 'Feature Viewed';
export const SEGMENT_EVENT_FEATURE_INTERACTED = 'Feature Interacted';

export const segmentTrackingProps = {
    view: {
        feature_category: 'Banner',
        feature_name: SHOP_SEGMENT_BANNER_FEATURE_NAME,
        feature_label: '',
    },
    click: {
        feature_category: 'Banner',
        feature_name: SHOP_SEGMENT_BANNER_FEATURE_NAME,
        feature_label: '',
        interaction_type: 'Click',
        interaction_label: '',
    },
    close: {
        feature_category: 'Banner',
        feature_name: SHOP_SEGMENT_BANNER_FEATURE_NAME,
        feature_label: '',
        interaction_type: 'Close',
        interaction_label: '',
    },
};
