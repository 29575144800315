import React, { ReactNode } from 'react';
import { HeaderContext, HeaderContextData } from './HeaderContext';

interface Props {
    fallbackComponent: ReactNode;
}

interface State {
    hasError: boolean;
}

class ErrorBoundary extends React.Component<Props, State> {
    // eslint-disable-next-line react/static-property-placement
    public static contextType = HeaderContext;

    public constructor(props: Props) {
        super(props);
        this.state = { hasError: false };
    }

    public static getDerivedStateFromError(): State {
        return { hasError: true };
    }

    public componentDidCatch(_error: Error): void {
        // log error to kibana
        const headerContext: HeaderContextData = this.context;
        headerContext.logger.error('Error occured in oww header', { _error });
    }

    public render(): ReactNode {
        const { hasError } = this.state;
        const { children, fallbackComponent } = this.props;
        if (hasError) {
            return fallbackComponent;
        }
        return children;
    }
}

export default ErrorBoundary;
