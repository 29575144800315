import styled from 'styled-components';

export const ScrollObserver = styled.div`
    position: absolute;
    width: 0;
    height: 0;
    margin: 0;
    padding: 0;
    left: 0;
    top: 0;
`;
