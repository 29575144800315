import { theme as sharedTheme } from '@westwing/ui-kit/Theme';

const headerCustomTheme = {
    ...sharedTheme,
    colors: {
        ...sharedTheme.colors,
        accentSurface: sharedTheme.colors.surfaceTurquoise,
        accentPrimary: sharedTheme.colors.turquoise100,
        accentPrimaryLight: sharedTheme.colors.turquoise30,
        tooltipBackground: sharedTheme.colors.charcoal100,
    },
};

export type ThemeType = typeof headerCustomTheme;

export default headerCustomTheme;
