import { DeviceType } from '@westwing/appshell_types/appshellMetaTypes';
import { isClient } from '../helpers/isClient';

const useDeviceType = (): { getByUserAgent: () => DeviceType } => {
    const getByUserAgent = (): DeviceType => {
        if (!isClient()) {
            return 'UNKNOWN';
        }

        const agent = navigator.userAgent || navigator.vendor;
        if (/Android/i.test(agent)) {
            return 'ANDROID';
        }
        if (/iPhone|iPad/i.test(agent)) {
            return 'IOS';
        }
        return 'UNKNOWN';
    };
    return { getByUserAgent };
};

export default useDeviceType;
