import React, { useState, useEffect } from 'react';
import styled, { css, BaseThemedCssFunction, DefaultTheme } from 'styled-components';
import useOverlayOutsideClick from '@westwing/ui-kit/Tools/hooks/useOverlayOutsideClick';
import { header6, caption1 } from '@westwing/ui-kit/typography';
import { forDesktop, forTablet, underDesktop } from '@westwing/ui-kit/breakPoints';
import CloseIcon from '@westwing/ui-kit/Icons/CloseIcon';
import Tag from '@westwing/ui-kit/Icons/TagIcon';
import Stars from '@westwing/ui-kit/Icons/StarsIcon';
import RoomPlanner from '@westwing/ui-kit/Icons/RoomPlanner';
import { HOTJAR_TRACKING_EVENTS, Tabs } from '../types/Header';
import { Cookies, getCookie } from '../utils/cookies';
import { setCookieWithImageRequest } from '../api/cookieService';
import { useHeaderContext } from './HeaderContext';
import { OnboardingTooltipConfig } from 'types/OnboardingTooltip';

const arrowSpace = 20;
const arrowSize = 17;
const contentSpace = Math.round(arrowSpace + arrowSize / 2);
const oneYear = 12 * 30 * 24 * 60 * 60 * 1000;

interface OnboardingWrapperProps {
    $tooltipFor: Tabs.Shop | Tabs.Club;
    $isDisplayedUnderDesktop: boolean;
}

export const OnboardingWrapper = styled.div<OnboardingWrapperProps>`
    ${({ $tooltipFor }) =>
        $tooltipFor === Tabs.Shop
            ? css`
                  left: 50%;
                  transform: translateX(-50%);
              `
            : css`
                  right: calc(50% - ${contentSpace}px);
                  ${forDesktop`
                    right: auto;
                    left: calc(50% - ${contentSpace}px);
                `}
              `}
    box-sizing: border-box;
    z-index: ${({ theme }) => theme.zIndexContentOverlay};
    width: 248px;
    position: absolute;
    top: 54px;

    ${forTablet`
        top: 58px;
    `}

    ${(underDesktop as BaseThemedCssFunction<DefaultTheme>)<OnboardingWrapperProps>`
        display: ${({ $isDisplayedUnderDesktop }) => ($isDisplayedUnderDesktop ? 'block' : 'none')};
    `}

    ${forDesktop`
        top: 40px;
    `}
`;

export const OnboardingContentOverlay = styled.div`
    box-sizing: border-box;
    position: relative;
    width: 100%;
`;

export const OnboardingInsideWrapper = styled.div<{ $tooltipFor: Tabs.Shop | Tabs.Club }>`
    background: ${({ theme }) => theme.colors.tooltipBackground};
    box-sizing: border-box;
    padding: 16px 20px;

    :before {
        content: '';
        ${({ $tooltipFor }) =>
            $tooltipFor === Tabs.Shop
                ? css`
                      left: 0;
                      right: 0;
                      margin: 0 auto;
                  `
                : css`
                      right: ${arrowSpace}px;
                      ${forDesktop`
                        right: auto;
                        left: ${arrowSpace}px;
                    `}
                  `}
        background: ${({ theme }) => theme.colors.tooltipBackground};
        width: ${arrowSize}px;
        height: ${arrowSize}px;
        position: absolute;
        top: -8px;
        transform: rotate(45deg);
        box-sizing: border-box;
    }
`;

export const OnboardingTooltipHeader = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 2px;
    box-sizing: border-box;
`;

export const OnboardingTooltipHeaderText = styled.div`
    ${header6}
    color: ${({ theme }) => theme.colors.white};
    font-family: ${({ theme }) => theme.fonts.fontChronicleRoman};
    white-space: normal;
    margin: 0 0 0 8px;
    line-height: normal;
    box-sizing: border-box;
`;

export const OnboardingTooltipText = styled.p`
    ${caption1}
    color: ${({ theme }) => theme.colors.white};
    font-family: ${({ theme }) => theme.fonts.fontBrandonRegular};
    white-space: normal;
    margin: 0;
    box-sizing: border-box;
`;

export const IconWrapper = styled.div`
    color: ${({ theme }) => theme.colors.white};
    width: 20px;
`;

export const OnboardingTagIcon = styled(Tag)`
    color: ${({ theme }) => theme.colors.white};
`;

export const OnboardingStarsIcon = styled(Stars)`
    color: transparent;
    stroke: ${({ theme }) => theme.colors.white};
`;

export const OnboardingRoomPlannerIcon = styled(RoomPlanner)`
    color: ${({ theme }) => theme.colors.white};
`;

export const CloseButton = styled(CloseIcon)`
    position: absolute;
    width: 20px;
    height: 20px;
    right: 10px;
    top: 10px;
    color: ${({ theme }) => theme.colors.white};

    &:hover {
        cursor: pointer;
    }
`;

const OnboardingTooltip: React.FC<Omit<OnboardingTooltipConfig & { isStickyHeaderActive: boolean }, 'show'>> = ({
    tooltipFor,
    headerText,
    text,
    TooltipIcon,
    trackingAction,
    trackingLabel,
    isStickyHeaderActive,
}) => {
    const {
        app,
        trackingHelpers: {
            ga: { trackNonInteractiveGenericEvent },
            hotjar: { trackCustomEvent },
            braze: { logCustomEvent },
        },
    } = useHeaderContext();
    const [isActive, setIsActive] = useState<boolean>(false);

    const ref = useOverlayOutsideClick(() => setIsActive(false), true);

    const trackClubOnboardingTooltipEvents = () => {
        if (app === 'CLUB') {
            // Send Hotjar event
            trackCustomEvent(HOTJAR_TRACKING_EVENTS.tooltipShown);

            // Notify Braze that tooltip has been shown
            void logCustomEvent('onboarding_tooltip_shown');
        }

        // Send Impression tracking to GA
        trackNonInteractiveGenericEvent('Header', {
            action: trackingAction,
            label: trackingLabel,
        });
    };

    useEffect(() => {
        if (getCookie(Cookies.ONBOARDING_TOOLTIP_ENABLED) === '1' || isStickyHeaderActive) {
            return undefined;
        }

        setIsActive(true);

        // To track or call the cookie API only once
        if (!isActive) {
            trackClubOnboardingTooltipEvents();

            setCookieWithImageRequest(Cookies.ONBOARDING_TOOLTIP_ENABLED, '1', oneYear);
        }

        return undefined;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isStickyHeaderActive]);

    if (!isActive || !app) {
        return null;
    }

    return (
        <OnboardingWrapper
            data-testid="oww-header-onboarding-tooltip"
            ref={ref}
            $tooltipFor={tooltipFor}
            $isDisplayedUnderDesktop={!isStickyHeaderActive}
            aria-hidden={isStickyHeaderActive}
            tabIndex={0}
            role="tooltip"
        >
            <OnboardingContentOverlay>
                <CloseButton
                    data-testid="oww-header-onboarding-tooltip-close-button"
                    onClick={() => setIsActive(false)}
                    role="button"
                    aria-label="Close onboarding tooltip"
                    tabIndex={0}
                />
                <OnboardingInsideWrapper $tooltipFor={tooltipFor}>
                    <OnboardingTooltipHeader>
                        <IconWrapper>{TooltipIcon}</IconWrapper>
                        <OnboardingTooltipHeaderText tabIndex={0} role="heading">
                            {headerText}
                        </OnboardingTooltipHeaderText>
                    </OnboardingTooltipHeader>
                    <OnboardingTooltipText tabIndex={0}>{text}</OnboardingTooltipText>
                </OnboardingInsideWrapper>
            </OnboardingContentOverlay>
        </OnboardingWrapper>
    );
};

export default OnboardingTooltip;
