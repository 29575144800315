import { Dispatch, RefObject, SetStateAction, useEffect, useRef, useState } from 'react';
import { noop } from '../utils/noop';

const defaultOptions: IntersectionObserverInit = {
    root: null,
    threshold: 1,
};

export const useIsVisible = (
    onChange: (isVisible: boolean) => void,
    options?: RefObject<IntersectionObserverInit>
): Dispatch<SetStateAction<Element | null>> => {
    const [elRef, setElRef] = useState<Element | null>(null);
    const intersectionObserver = useRef<IntersectionObserver>();

    useEffect(() => {
        if (!elRef) {
            return noop;
        }

        const onVisibilityChange = (rect: DOMRect) => {
            onChange(rect.top >= 0);
        };

        if (!intersectionObserver.current) {
            intersectionObserver.current = new IntersectionObserver(
                ([entry]) => {
                    onVisibilityChange(entry.boundingClientRect);
                },
                {
                    ...defaultOptions,
                    ...(options?.current || {}),
                }
            );
        }

        intersectionObserver.current.observe(elRef);
        onVisibilityChange(elRef.getBoundingClientRect());

        return () => {
            if (intersectionObserver.current) {
                intersectionObserver.current.disconnect();
            }
        };
    }, [elRef, options, onChange]);

    return setElRef;
};
