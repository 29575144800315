import styled, {
    BaseThemedCssFunction,
    css,
    DefaultTheme,
    FlattenInterpolation,
    ThemedStyledProps,
} from 'styled-components';
import breakpoints, {
    forDesktop,
    forMidWideScreens,
    forTablet,
    underDesktop,
    underMobile,
    underTablet,
} from '@westwing/ui-kit/breakPoints';
import IconButton from '@westwing/ui-kit/IconButton';
import { ScrollObserver } from '../ScrollObserver';
import { HeaderModeType } from '../../types/Header';
import { StyledOldWestwingLogo, StyledWestwingLogo } from 'Components/Logo';

enum Z_INDEX {
    UNDER_ALL = -1,
    BELOW_ALL = 1,
    STICKY = 2,
    MOCKED_OVERLAY = 3,
    BELOW_STATIC = 4,
    STATIC = 5,
    ABOVE_STATIC = 6,
}

const iconWrapperWidth = 48;
const iconTrayWrapperWidth = iconWrapperWidth + 12;
const middleWrapperMobileOffset = iconWrapperWidth + iconTrayWrapperWidth;

export interface StyledHeaderWrapperProps {
    $isStickyEnabled?: boolean;
    $headerMode?: HeaderModeType;
}

export const stickyHeaderWrapperCss = (
    isStickyEnabled = false,
    $headerMode = HeaderModeType.DEFAULT
): FlattenInterpolation<ThemedStyledProps<Record<string, unknown>, DefaultTheme>> => css<StyledHeaderWrapperProps>`
    z-index: ${({ theme }) => theme.zindexOneHeaderNavBar};

    ${(underDesktop as BaseThemedCssFunction<DefaultTheme>)<StyledHeaderWrapperProps>`
        display: block;
        position: ${isStickyEnabled ? 'sticky' : 'relative'};
        ${({ theme }) =>
            isStickyEnabled
                ? css`
                      top: -${theme.owwHeader.tabletRowHeightSelected}px;
                  `
                : 'top: unset;'}
    `}

    ${(underTablet as BaseThemedCssFunction<DefaultTheme>)<StyledHeaderWrapperProps>`
        ${({ theme }) =>
            isStickyEnabled
                ? css`
                      top: -${$headerMode === HeaderModeType.SECOND_ROW ? 0 : theme.owwHeader.mobileRowHeightSelected}px;
                  `
                : 'top: unset;'}
    `}

    ${forDesktop`
        display: block;
        position: relative;
    `}
`;

const HeaderLine = css`
    display: flex;
    position: relative;
    height: ${({ theme }) => theme.owwHeader.mobileRowHeight}px;
    background-color: white;
    box-sizing: border-box;

    ${(forTablet as BaseThemedCssFunction<DefaultTheme>)`
        height: ${({ theme }) => theme.owwHeader.tabletRowHeight}px;
    `}
    ${(forDesktop as BaseThemedCssFunction<DefaultTheme>)`
        height: ${({ theme }) => theme.owwHeader.desktopRowHeight}px;
    `}
`;

const PrimaryLineStyles = css`
    ${HeaderLine}
    z-index: ${Z_INDEX.STATIC};
`;

const SecondaryLineStyles = css`
    ${HeaderLine}
    z-index: ${Z_INDEX.STICKY};
`;

export const StyledBaseHeader = styled.header<StyledHeaderWrapperProps>`
    ${({ $isStickyEnabled, $headerMode }) => stickyHeaderWrapperCss($isStickyEnabled, $headerMode)}
    z-index: ${({ theme }) => theme.owwHeader.zIndexes.baseHeader};
    background-color: white;
`;

export const HeaderContentWrapper = styled.div`
    width: 100%;
    z-index: ${({ theme }) => theme.owwHeader.zIndexes.middleBlock};
    position: relative;
    ${forDesktop`
        border-bottom: ${({ theme }) => `1px solid ${theme.colors.charcoal20};`}
    `}
`;

export const BeforeHeaderWrapper = styled.div`
    z-index: ${({ theme }) => theme.owwHeader.zIndexes.topBlock};
    position: relative;
`;
export const AfterHeaderWrapper = styled.div`
    z-index: ${({ theme }) => theme.owwHeader.zIndexes.bottomBlock};
    position: relative;
`;

export const LogoWrapper = styled.div`
    ${PrimaryLineStyles}

    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 14px;

    ${forDesktop`
        padding: 0 50px 0 15px;
    `}

    ${forMidWideScreens`
        padding: 0 50px 0 40px;
    `}
`;

export const BurgerMenuWrapper = styled.div`
    ${SecondaryLineStyles}
    align-items: center;

    ${forDesktop`
        display: none;
    `}
`;

export const TabsWrapper = styled.div`
    ${PrimaryLineStyles}
    z-index: ${Z_INDEX.ABOVE_STATIC};

    flex: 1;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-right: 14px;

    ${forDesktop`
        justify-content: center;
        flex: 0;
        padding: 0;
    `}
`;

export const MiddleWrapper = styled.div`
    ${underDesktop`${SecondaryLineStyles}`}
    ${forDesktop`${PrimaryLineStyles}`}
    
    ${underMobile`
        max-width: calc(100vw - ${middleWrapperMobileOffset}px);
    `}

    flex: 1;
    align-items: center;
    justify-content: space-between;
`;

export const IconTrayWrapper = styled.div`
    padding: 0 20px;
    align-items: center;
    justify-content: center;

    ${underDesktop`
        ${SecondaryLineStyles}
        padding: 0 6px 0 16px;
    `}

    ${underTablet`
        padding: 0 0 0 12px;
    `}

    ${forDesktop`
        ${PrimaryLineStyles}
        padding-right: 0;
    `}

    ${forMidWideScreens`
        padding-right: 35px;
    `}
`;

export const LineBreaker = styled.hr`
    flex-basis: 100%;
    height: 1px;
    margin: 0;
    border: 0;
    background: ${({ theme }) => theme.colors.accentPrimaryLight};
    z-index: ${Z_INDEX.BELOW_STATIC};

    ${forDesktop`
        display: none;
    `}
`;

export const BackIconButtonWrapper = styled.div`
    display: none;
`;

export const BackIconButton = styled(IconButton)`
    align-self: center;
    min-width: 48px;
    color: ${({ theme }) => theme.colors.charcoal100};
    background-color: transparent;
    border: none;
`;

export const Shadow = styled.div<{ $isHidden?: boolean }>`
    display: none;
    width: 100%;
    height: ${({ theme }) => theme.owwHeader.shadowHeight}px;
    background-image: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0));
    top: ${({ theme }) => theme.owwHeader.mobileRowHeight}px;
    z-index: ${Z_INDEX.UNDER_ALL};

    ${forTablet`
        top: ${({ theme }) => theme.owwHeader.tabletRowHeight}px;
    `}

    ${(underDesktop as BaseThemedCssFunction<DefaultTheme>)<{ $isHidden?: boolean }>`
        display: ${({ $isHidden }) => ($isHidden ? 'none' : 'block')};
        margin-bottom: -2px;
    `}
`;

export const StyledLogoWrapper = styled.div`
    ${SecondaryLineStyles}

    flex: 1;
    align-items: center;
    justify-content: space-between;
    ${forDesktop`
        display: none;
    `}
`;

export const StickyArea = styled.div<{ $hasMobileStickyHeader: boolean; $hasAppBanner?: boolean }>`
    position: sticky;
    top: ${({ $hasMobileStickyHeader, $hasAppBanner, theme }) =>
        $hasMobileStickyHeader ? theme.owwHeader.mobileRowHeight + ($hasAppBanner ? theme.smartBannerHeight : 0) : 0}px;

    z-index: ${({ theme }) => theme.zIndexBelowContentOverlay};

    ${(forTablet as BaseThemedCssFunction<DefaultTheme>)<{ $hasMobileStickyHeader: boolean }>`
        top: ${({ $hasMobileStickyHeader, theme }) => ($hasMobileStickyHeader ? theme.owwHeader.tabletRowHeight : 0)}px;
    `}

    ${forDesktop`
        top: 0;
    `}
`;

export const MockedStickyOverlayLine = styled.div<{ $isHidden?: boolean }>`
    height: ${({ theme }) => theme.owwHeader.mobileRowHeight}px;
    z-index: ${Z_INDEX.MOCKED_OVERLAY};
    position: absolute;
    width: 100%;
    flex: 1;
    top: 0;
    left: 0;
    background: linear-gradient(to bottom, white, white 80%, rgba(255, 255, 255, 0));
    display: ${({ $isHidden }) => ($isHidden ? 'none' : 'flex')};
    align-items: center;

    ${forTablet`
        height: ${({ theme }) => theme.owwHeader.tabletRowHeight}px;
    `}

    ${forDesktop`
        display: none;
    `}
`;

export const HeaderContent = styled.div`
    width: 100%;
    max-width: calc(${breakpoints.breakpointWideScreens} + 80px);
    display: flex;
    flex-wrap: wrap;
    margin: 0 auto;

    ${underTablet`
        &[data-header-mode='${HeaderModeType.SECOND_ROW}'] {
            justify-content: space-between;
            & > ${LogoWrapper}, & > ${TabsWrapper}, & > ${LineBreaker} {
                display: none;
            }
            & > ${StyledWestwingLogo}, & > ${StyledOldWestwingLogo} {
                display: block;
            }
            & > ${BackIconButtonWrapper} {
                display: grid;
            }
        }
    `}

    ${underDesktop`
        &[data-header-mode='${HeaderModeType.MINI}'] {
            justify-content: space-between;
            & > ${LogoWrapper}, & > ${TabsWrapper}, & > ${LineBreaker}, & > ${BurgerMenuWrapper}, & > ${MiddleWrapper} {
                display: none;
            }
            & > ${StyledWestwingLogo}, & > ${StyledOldWestwingLogo} {
                display: block;
            }
            & > ${BackIconButtonWrapper} {
                display: grid;
            }
        }
    `}
    ${forDesktop`
        ${StyledLogoWrapper} {
            display: none;
        }
    `}
`;

export const HeaderScrollObservable = styled(ScrollObserver)`
    top: ${({ theme }) => theme.owwHeader.mobileRowHeight - 1}px;

    ${forTablet`
        top: ${({ theme }) => theme.owwHeader.tabletRowHeight - 1}px;
    `}

    ${forDesktop`
        display: none;
    `}
`;
