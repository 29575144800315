import React, { ReactNode } from 'react';
import { MiniHeaderConfig } from '../../types/MiniHeader';
import { HeaderModeType } from '../../types/Header';
import InfoBlock from '../InfoBlock';
import { useSmartAppBanner } from '../../hooks/useSmartAppBanner';
import { HeaderBase, HeaderBaseProps } from './HeaderBase';
import { StickyArea } from './HeaderElements';
import { IconTrayVisibility } from 'Components/IconTray';

export interface HeaderProps
    extends Omit<
        HeaderBaseProps,
        'iconTrayComponents' | 'iconTrayVisibility' | 'beforeHeaderComponent' | 'smartAppBannerConfig'
    > {
    iconTrayComponents?: ReactNode[];
    miniHeaderConfig?: MiniHeaderConfig;
    infoBlockComponents?: ReactNode[];
    iconTrayVisibility?: IconTrayVisibility;
    hasBarBanner?: boolean;
    isStuck?: boolean;
    stickyAreaComponents?: ReactNode;
    smartAppBannerUrl?: string;
    isDocumentCookie?: boolean;
    shouldShowAppBanner?: boolean;
}

const Header = ({
    burgerMenuConfig,
    iconTrayComponents = [],
    iconTrayVisibility = 'visible',
    middleAreaComponent,
    miniHeaderConfig,
    headerMode = HeaderModeType.DEFAULT,
    tabsConfig,
    infoBlockComponents,
    onStickyChange,
    stickyAreaComponents,
    isStickyEnabled,
    afterHeaderComponent,
    logoHref,
    hasBarBanner,
    logoConfig,
    isStuck,
    onboardingTooltipConfig,
    shouldShowAppBanner = false,
    isDocumentCookie = false,
    smartAppBannerUrl,
}: HeaderProps): JSX.Element | null => {
    const smartAppBannerConfig = useSmartAppBanner(shouldShowAppBanner, isDocumentCookie, smartAppBannerUrl);

    if (headerMode === HeaderModeType.NONE) {
        return null;
    }

    const isMiniHeader = !!(headerMode === HeaderModeType.MINI && miniHeaderConfig);
    return (
        <>
            <HeaderBase
                smartAppBannerConfig={smartAppBannerConfig}
                miniHeaderConfig={miniHeaderConfig}
                burgerMenuConfig={burgerMenuConfig}
                iconTrayComponents={iconTrayComponents}
                iconTrayVisibility={iconTrayVisibility}
                middleAreaComponent={middleAreaComponent}
                tabsConfig={tabsConfig}
                onStickyChange={onStickyChange}
                isStickyEnabled={isStickyEnabled}
                beforeHeaderComponent={
                    infoBlockComponents && !hasBarBanner && <InfoBlock components={infoBlockComponents} />
                }
                afterHeaderComponent={afterHeaderComponent}
                headerMode={headerMode}
                logoHref={logoHref}
                logoConfig={logoConfig}
                isStuck={isStuck}
                onboardingTooltipConfig={onboardingTooltipConfig}
            />
            <StickyArea
                $hasAppBanner={smartAppBannerConfig.isShown}
                $hasMobileStickyHeader={!isMiniHeader && !!isStickyEnabled}
            >
                {stickyAreaComponents}
            </StickyArea>
        </>
    );
};

export default Header;
