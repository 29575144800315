import BurgerMenuIcon from '@westwing/ui-kit/Icons/BurgerMenuIcon';
import * as React from 'react';
import styled from 'styled-components';
import IconButton from '@westwing/ui-kit/IconButton';
import { BurgerMenuConfig } from '../types/BurgerMenu';

const StyledBurgerMenu = styled(IconButton)`
    color: ${({ theme }) => theme.colors.charcoal100};
`;

export const BurgerMenu = ({ onClick, dataTestId, className }: BurgerMenuConfig): JSX.Element => (
    <StyledBurgerMenu aria-label="Menu" onClick={onClick} data-testid={dataTestId} className={className}>
        <BurgerMenuIcon />
    </StyledBurgerMenu>
);
