import { addUrlParam } from '../utils/urls';
import { ClubLink, ClubLinksType } from './types/clubLinksTypes';

export const clubLinks: ClubLinksType = {
    [ClubLink.CART]: { path: '/cart/' },
    [ClubLink.CART_V2]: { path: '/shop/cart/' },
    [ClubLink.CUSTOMER_ACCOUNT]: { path: '/customer/account' },
    [ClubLink.CUSTOMER_ACCOUNT_CREATE_INVITATION]: { path: '/customer/invitation/create/' },
    [ClubLink.CUSTOMER_ACCOUNT_HEADERDATA]: { path: '/customer/account/headerData/' },
    [ClubLink.BFF_CUSTOMER_ACCOUNT_HEADER]: { path: '/header/' },
    [ClubLink.CUSTOMER_ORDER]: { path: '/customer/order/' },
    [ClubLink.CUSTOMER_NEWSLETTER]: { path: '/customer/newsletter/' },
    [ClubLink.CUSTOMER_NOTIFICATIONS]: { path: '/customer/notification/' },
    [ClubLink.HELPCENTER]: { path: '/helpcenter/' },
    [ClubLink.LOGOUT]: { path: '/customer/account/logout/' },
    [ClubLink.CAMPAIGN]: { path: '/campaign/' },
    [ClubLink.CAMPAIGN_UPCOMING]: { path: '/campaign/upcoming/' },
    [ClubLink.CART_REQUEST]: { path: '/cart/timer/' },
    [ClubLink.CART_RESTORE]: { path: '/cart/restore/' },
    [ClubLink.CUSTOMER_COUPON]: { path: '/customer/coupon/' },
    [ClubLink.ONE_CART_REQUEST]: { path: '/now-api/web/v2/cart/:country/cart/' },
    [ClubLink.ONE_CART_METADATA]: { path: '/now-api/web/v2/cart/:country/cart/metadata/' },
};

export const prefixClubLinks = (linkPrefix: string): ClubLinksType =>
    Object.keys(clubLinks).reduce((links, key) => {
        // eslint-disable-next-line no-param-reassign
        links[key] = { ...clubLinks[key], path: linkPrefix + clubLinks[key].path };
        return links;
    }, {} as ClubLinksType);

export const getOneCartLink = (): string => addUrlParam(clubLinks[ClubLink.CART_V2].path, 'businessOrigin', 'club');
export const getOpenClubOneCartLink = (): string => addUrlParam(getOneCartLink(), 'pr', 'fetch');
