import React, { useMemo } from 'react';
import styled, { css, DefaultTheme, useTheme } from 'styled-components';
import { body2, caption1 } from '@westwing/ui-kit/typography';
import { forTablet, underIPhone5 } from '@westwing/ui-kit/breakPoints';
import { CrossIcon } from '@westwing/ui-kit/Icons/StyledIcons';
import OldAppLogoIcon, { SvgAppLogo } from '@westwing/ui-kit/Icons/Svgs/AppLogoIcon';
import OldClubAppLogoIcon from '@westwing/ui-kit/Icons/ClubAppLogoIcon';
import { SmartAppBannerConfig } from '../../types/SmartAppBanner';
import { ClubAppLogoComponent } from '../../club/AppLogo';
import { ClubPOKey, useHeaderContext } from 'Components/HeaderContext';

const StickyBannerStyle = css`
    position: sticky;
    top: 0;
    z-index: ${({ theme }) => theme.zindexSmartBanner};
`;

const Wrapper = styled.div<{ $shown: boolean }>`
    ${StickyBannerStyle}
    background: ${({ theme }) => theme.colors.charcoal100};
    color: ${({ theme }) => theme.colors.white};
    height: ${({ theme }) => `${theme.smartBannerHeight}px`};
    display: ${({ $shown }) => ($shown ? 'flex' : 'none')};
    ${forTablet`
        display: none;
    `}
`;

const CTA = styled.div`
    background: ${({ theme }) => theme.colors.turquoise100};
    padding: 8px;
    ${body2}
    margin-right: 20px;
`;

const Title = styled.p`
    margin: 0;
    ${body2}
    line-height: 1.25;
    color: inherit;
`;

const SubTitle = styled.p`
    margin: 0;
    ${caption1}
    line-height: 1.25;
    color: inherit;
`;

const StyledCrossIcon = styled(CrossIcon)`
    margin: 10px;
`;

const Texts = styled.div`
    flex: 1;
    margin-right: 3px;
`;

const OpenSector = styled.button`
    width: 86%;
    height: 100%;
    z-index: 1;
`;

const CloseSector = styled.button`
    width: 14%;
    height: 100%;
    z-index: 1;
`;

const Content = styled.div`
    top: 0;
    height: 100%;
    width: 100%;
    position: absolute;
    display: flex;
    align-items: center;
    z-index: 0;
`;

const logoStyle = css`
    margin-right: 11px;
    height: 64px;
    width: 64px;
    ${underIPhone5`
        height: 44px;
        width: 44px;
    `}
`;

const OldShopAppLogo = styled(OldAppLogoIcon)`
    ${logoStyle}
`;

/**
 * This is required for Club to handle smart banner height in overlays like Instant PDP
 */
const getGlobalHeightVariable = (theme: DefaultTheme) => `
    :root {
        --smart-banner-height: ${theme.smartBannerHeight}px;
    }
`;

export const OldClubAppLogo = styled(OldClubAppLogoIcon)`
    ${logoStyle}
`;

export const AppLogo = styled(SvgAppLogo)`
    ${logoStyle}
`;

export const shopPOKeys = {
    title: 'Smart banner_Headline',
    subTitle: 'Smart banner_Subheadline',
    callToAction: 'To the App',
};

export const clubPOKeys: Record<string, ClubPOKey> = {
    title: 'OwwHeader_Exclusive Sales & Offers',
    subTitle: 'OwwHeader_Now in the Westwing App',
    callToAction: 'OwwHeader_To App',
};

const SmartAppBanner: React.FC<{ smartAppBannerConfig: SmartAppBannerConfig }> = ({ smartAppBannerConfig }) => {
    const { app, t, useNewLogo } = useHeaderContext();
    const isShop = useMemo(() => app === 'SHOP', [app]);
    const { title, subTitle, callToAction } = isShop ? shopPOKeys : clubPOKeys;
    const theme = useTheme();
    const { isShown, isClubApp } = smartAppBannerConfig;
    const globalHeightVariable = useMemo(() => getGlobalHeightVariable(theme), [theme]);

    const oldShopLogo = isClubApp ? <OldClubAppLogo /> : <OldShopAppLogo />;
    const shopLogo = useNewLogo ? <AppLogo /> : oldShopLogo;
    const logo = isShop ? shopLogo : <ClubAppLogoComponent />;

    return (
        <Wrapper $shown={isShown} role="banner" aria-label={t(title)} data-testid="smartBanner">
            {/* eslint-disable-next-line react/no-danger */}
            {isShown && <style dangerouslySetInnerHTML={{ __html: globalHeightVariable }} />}
            <CloseSector data-testid="close" aria-label="Close banner" onClick={smartAppBannerConfig.close} />
            <OpenSector data-testid="open" aria-label={t(callToAction)} onClick={smartAppBannerConfig.openInApp} />
            <Content>
                <StyledCrossIcon color="white" />
                {logo}
                <Texts>
                    <Title>{t(title)}</Title>
                    <SubTitle>{t(subTitle)}</SubTitle>
                </Texts>
                <CTA>{t(callToAction)}</CTA>
            </Content>
        </Wrapper>
    );
};

export default SmartAppBanner;
