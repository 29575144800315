import React, { useMemo } from 'react';
import styled, { css, keyframes } from 'styled-components';
import OldWestwingIcon, { WestwingIcon } from '@westwing/ui-kit/Icons/Westwing';
import { forDesktop } from '@westwing/ui-kit/breakPoints';
import { LogoConfig } from '../types/Logo';
import { ClubLogoComponent } from '../club/Logo';
import { useHeaderContext } from 'Components/HeaderContext';

const logoAppearAnimation = keyframes`
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
`;

export interface LogoProps extends LogoConfig {
    $appear?: boolean;
    $isHidden?: boolean;
    $mobileOnly?: boolean;
    href: string;
}

export type LogoCssProps = Omit<LogoProps, 'href'>;

const westwingLogoStyles = css<LogoCssProps>`
    width: 99px;

    ${forDesktop`
        width: 183px;
    `}

    ${({ $appear }) =>
        $appear &&
        css`
            animation: ${logoAppearAnimation} 1s ease;
        `}

    ${({ $isHidden }) => $isHidden && 'display: none;'}

    ${({ $mobileOnly }) =>
        $mobileOnly &&
        forDesktop`
            display: none;
        `}
`;

export const StyledOldWestwingLogo = styled(OldWestwingIcon)`
    ${westwingLogoStyles}
`;

export const StyledWestwingLogo = styled(WestwingIcon)`
    ${westwingLogoStyles}
`;

const WestwingLogoAnchor = styled.a`
    line-height: 0;
`;

export const Logo = ({
    $appear,
    $isHidden,
    $mobileOnly,
    href,
    handleBurgerMenuCloseClick,
    linkComponent,
}: LogoProps): JSX.Element => {
    const {
        app,
        trackingHelpers: { ga, useCurrentPageType },
        useNewLogo,
    } = useHeaderContext();
    const isShop = useMemo(() => app === 'SHOP', [app]);

    const { pageType } = useCurrentPageType();

    const handleClick = () => {
        if (handleBurgerMenuCloseClick) {
            handleBurgerMenuCloseClick();
        }
        ga.trackNonInteractiveGenericEvent('Header', { action: 'Header Logo - Click', label: `${pageType}` });
    };

    const ShopLogoComponent = useNewLogo ? StyledWestwingLogo : StyledOldWestwingLogo;
    const LogoComponent = isShop ? ShopLogoComponent : ClubLogoComponent;

    return (
        <WestwingLogoAnchor
            as={linkComponent}
            to={href}
            href={href}
            data-testid="header-logo-link"
            onClick={handleClick}
        >
            <LogoComponent aria-label="Westwing" $appear={$appear} $isHidden={$isHidden} $mobileOnly={$mobileOnly} />
        </WestwingLogoAnchor>
    );
};
