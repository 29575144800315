export enum HeaderModeType {
    DEFAULT = 'default',
    FIRST_ROW = 'first_row',
    SECOND_ROW = 'second_row',
    MINI = 'mini',
    NONE = 'none',
}

export enum Tabs {
    Shop = 'SHOP',
    Club = 'CLUB',
}

export const HOTJAR_TRACKING_EVENTS = {
    tabClick: 'OneWestwing_Header_Tab_Click',
    tooltipShown: 'Onboarding_Tooltip_impression',
};
