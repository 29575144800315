import * as React from 'react';
import styled from 'styled-components';
import { SvgAppLogo } from '@westwing/ui-kit/Icons/Svgs/AppLogoIcon';
import { underIPhone5 } from '@westwing/ui-kit/breakPoints';
import { useClubContext } from './hooks/ClubContext';
import { OldClubAppLogo } from 'Components/banners/SmartAppBanner';

export const ClubAppLogoContainer = styled.div<{ active: string }>`
    [data-type] {
        display: none;
    }
    [data-type='${({ active }) => active}'] {
        display: block !important;
    }
`;

export const AppLogo = styled(SvgAppLogo)`
    svg {
        margin-right: 11px;
        height: 64px;
        width: 64px;
        ${underIPhone5`
            height: 44px;
            width: 44px;
        `}
    }
`;

export const ClubAppLogoComponent = (): JSX.Element => {
    const { clubCiVersion } = useClubContext();

    return (
        <ClubAppLogoContainer active={clubCiVersion}>
            <OldClubAppLogo data-type="old" />
            <AppLogo data-type="new" />
        </ClubAppLogoContainer>
    );
};
