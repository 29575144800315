/* eslint-disable camelcase */
import { DeviceType } from '@westwing/appshell_types/appshellMetaTypes';
import buildUrl from 'build-url';
import { FeatureToggles } from '@westwing/appshell_types';

export const WWNowURL = 'https://zwyf.adj.st';
export const WWNowTrackers: Partial<Record<DeviceType, string>> = { IOS: '59s8kng', ANDROID: 'ish46dg' };

export const WWURL = 'https://82ef.adj.st';
export const WWTrackers: Partial<Record<DeviceType, string>> = { IOS: 'fc5qss6_zq7e1er', ANDROID: 'fc5qss6_zq7e1er' };

const getWWNowUrlParams = () => ({ url: WWNowURL, tracker: WWNowTrackers });
const getOneWWUrlParams = () => ({ url: WWURL, tracker: WWTrackers });

export const getSmartAppBannerUrl = (host: string, device: DeviceType, featureToggles?: FeatureToggles): string => {
    const isOneWWLink =
        featureToggles?.p100ChangeSmartAppBannerLink?.includes(device.toLowerCase()) ||
        featureToggles?.p100ChangeSmartAppBannerLink === 'all';
    const { tracker, url } = isOneWWLink ? getOneWWUrlParams() : getWWNowUrlParams();

    const deepLinkURL = window.location.href;
    const fallbackURL = `${host}/m/app/`;

    return buildUrl(url, {
        queryParams: {
            adjust_t: tracker[device] || '',
            adjust_deeplink: deepLinkURL,
            adjust_fallback: fallbackURL,
        },
    });
};
