import { createContext, useContext } from 'react';
import type {
    GaTracking,
    MicroFrontendAppShellProps,
    BrazeTracking,
    HotjarTracking,
    SegmentTracking,
} from '@westwing/appshell_types';
import { Logger } from '../types/Logger';
import { defaultContextLogger } from 'Components/HeaderContextDefaults';

export const HeaderContext = createContext<HeaderContextData>({
    app: 'SHOP',
    t: () => {
        throw new Error('There is no implementation in place for the translation function');
    },
    logger: defaultContextLogger,
    trackingHelpers: {
        ga: {
            trackNonInteractiveGenericEvent: () => {
                throw new Error('There is no implementation in place for the trackGenericNonInteractiveEvent function');
            },
            trackInteractiveGenericEvent: () => {
                throw new Error('There is no implementation in place for the trackInteractiveGenericEvent function');
            },
        },
        braze: {
            logCustomEvent: () => {
                throw new Error('There is no implementation in place for the braze logCustomEvent function');
            },
        },
        hotjar: {
            trackCustomEvent: () => {
                throw new Error('There is no implementation in place for the hotjar trackCustomEvent function');
            },
        },
        segment: {
            track: () => {
                throw new Error('There is no implementation in place for the segment track function');
            },
        },
        useCurrentPageType: () => {
            throw new Error('There is no implementation in place for the useCurrentPageType hook');
        },
    },
    useNewLogo: false,
});

export const HeaderContextProvider = HeaderContext.Provider;

export const useHeaderContext = (): HeaderContextData => {
    const context = useContext<HeaderContextData>(HeaderContext);

    if (!context) {
        throw new Error('useHeaderContext must be used within HeaderContextProvider');
    }

    return context;
};

export type TrackingHelpers = {
    ga: {
        trackNonInteractiveGenericEvent: GaTracking['trackGenericEvent'];
        trackInteractiveGenericEvent: GaTracking['trackInteractiveGenericEvent'];
    };
    braze: {
        logCustomEvent: BrazeTracking['logCustomEvent'];
    };
    hotjar: { trackCustomEvent: HotjarTracking['trackCustomEvent'] };
    segment: { track: SegmentTracking['track'] };
    useCurrentPageType: MicroFrontendAppShellProps['routingHelpers']['useCurrentPageType'];
};

export type HeaderContextData = {
    app: 'SHOP' | 'CLUB';
    t: (key: POKey, replacements?: string[]) => string;
    logger: Logger;
    trackingHelpers: TrackingHelpers;
    useNewLogo?: boolean;
};

/**
 * In Club we use the `OwwHeader_` prefix to identify which PO Keys the Header will require,
 * and then send them to the client side render. So for now it is mandatory to add it to all
 * PO Keys used in the project, as otherwise it won't work properly in client side rendering.
 */
export type ClubPOKey = `OwwHeader_${string}`;
export type ShopPOKey = string;
export type POKey = ClubPOKey | ShopPOKey;
