export enum Cookies {
    ONBOARDING_TOOLTIP_ENABLED = 'has_user_seen_onboarding_tooltip_before',
    APP_BANNER_DISMISSED = 'ww_smart_app_banner_dismissed',
}

export const setDocumentCookie = (name: string, value: string, tti?: number): void => {
    let expires = '';
    if (tti) {
        const date = new Date();
        date.setTime(date.getTime() + tti);
        expires = `; expires=${date.toUTCString()}`;
    }
    document.cookie = `${name}=${value || ''}${expires}; path=/`;
};

export const getCookie = (name: string): string | undefined => {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) {
        return parts.pop()?.split(';').shift();
    }
    return undefined;
};
