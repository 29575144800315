import { Logger } from '../types/Logger';

const getContextMock = <T>(message: string) =>
    new Proxy(
        {},
        {
            get(_, prop) {
                throw new Error(`${message} ${prop.toString()}`);
            },
        }
    ) as T;

export const defaultContextLogger = getContextMock<Logger>('There is no implementation in place for the logger.');
