import { createContext, useContext } from 'react';
import { ClubLinksType } from '../types/clubLinksTypes';
import { clubLinks } from '../clubLinks';

const ClubContext = createContext<ClubContextType>({
    links: clubLinks,
    serverSide: true,
    isOpenClubEnabled: false,
    clubCiVersion: 'old',
});

export const ClubContextProvider = ClubContext.Provider;

export const useClubContext = (): ClubContextType => {
    const context = useContext<ClubContextType>(ClubContext);

    if (!context) {
        throw new Error('useClubContext must be used within ClubContextProvider');
    }

    return context;
};

export type ClubContextType = {
    links: ClubLinksType;
    serverSide: boolean;
    isOpenClubEnabled: boolean;
    clubCiVersion: string;
};
