import React, { FC, ReactNode, useCallback, useMemo } from 'react';
import ArrowIcon from '@westwing/ui-kit/Icons/ArrowIcon';
import { BurgerMenuConfig } from '../../types/BurgerMenu';
import { TabsNavItem } from '../../types/Navigation';
import { useIsVisible } from '../../hooks/useIsVisible';
import { HeaderModeType } from '../../types/Header';
import { Logo } from '../Logo';
import { IconTray, IconTrayVisibility } from '../IconTray';
import { BurgerMenu } from '../BurgerMenu';
import { Tabs } from '../Tabs';
import { MiniHeaderConfig } from '../../types/MiniHeader';
import { LogoConfig } from '../../types/Logo';
import { SmartAppBannerConfig } from '../../types/SmartAppBanner';
import {
    AfterHeaderWrapper,
    BackIconButtonWrapper,
    BackIconButton,
    BeforeHeaderWrapper,
    BurgerMenuWrapper,
    HeaderContent,
    HeaderContentWrapper,
    HeaderScrollObservable,
    IconTrayWrapper,
    LineBreaker,
    LogoWrapper,
    MiddleWrapper,
    Shadow,
    StyledBaseHeader,
    StyledLogoWrapper,
    TabsWrapper,
} from './HeaderElements';
import ErrorBoundary from 'Components/ErrorBoundary';
import { OnboardingTooltipConfig } from 'types/OnboardingTooltip';
import SmartAppBanner from 'Components/banners/SmartAppBanner';

export interface HeaderBaseProps {
    isStickyEnabled?: boolean;
    onStickyChange?: (isStuck: boolean) => void;
    headerMode?: HeaderModeType;
    afterHeaderComponent?: ReactNode;
    beforeHeaderComponent?: ReactNode;
    tabsConfig: TabsNavItem[];
    middleAreaComponent?: ReactNode;
    miniHeaderConfig?: MiniHeaderConfig;
    iconTrayComponents?: ReactNode[];
    iconTrayVisibility: IconTrayVisibility;
    burgerMenuConfig?: BurgerMenuConfig;
    logoHref: string;
    logoConfig?: LogoConfig;
    isStuck?: boolean;
    onboardingTooltipConfig?: OnboardingTooltipConfig;
    smartAppBannerConfig: SmartAppBannerConfig;
}

// eslint-disable-next-line complexity
export const HeaderBase: FC<HeaderBaseProps> = ({
    iconTrayComponents,
    iconTrayVisibility,
    burgerMenuConfig,
    middleAreaComponent,
    miniHeaderConfig,
    tabsConfig,
    isStickyEnabled,
    onStickyChange,
    afterHeaderComponent,
    beforeHeaderComponent,
    headerMode,
    logoHref,
    logoConfig,
    isStuck,
    onboardingTooltipConfig,
    smartAppBannerConfig,
}: HeaderBaseProps) => {
    const [isStickyHeaderActive, setIsStickyHeaderActive] = React.useState(false);

    const onVisibilityChange = useCallback(
        (isVisible: boolean): void => {
            setIsStickyHeaderActive(!isVisible);

            if (onStickyChange) {
                onStickyChange(!isVisible);
            }
        },
        [onStickyChange]
    );

    const setRef = useIsVisible(onVisibilityChange);

    const isMiniMode = useMemo(() => headerMode === HeaderModeType.MINI, [headerMode]);
    const isSecondRowMode = useMemo(() => headerMode === HeaderModeType.SECOND_ROW, [headerMode]);

    if (headerMode === HeaderModeType.FIRST_ROW) {
        return (
            <StyledBaseHeader data-testid="oww-header">
                <HeaderContentWrapper>
                    <HeaderContent>
                        <LogoWrapper>
                            <Logo
                                href={logoHref}
                                linkComponent={logoConfig?.linkComponent}
                                handleBurgerMenuCloseClick={logoConfig?.handleBurgerMenuCloseClick}
                            />
                        </LogoWrapper>
                        <TabsWrapper>
                            <Tabs navItems={tabsConfig} onboardingTooltipConfig={onboardingTooltipConfig} />
                        </TabsWrapper>

                        <LineBreaker />
                    </HeaderContent>
                </HeaderContentWrapper>
            </StyledBaseHeader>
        );
    }

    const HeaderShadow = isStickyEnabled && <Shadow $isHidden={!isMiniMode && !isStuck} />;
    return (
        <StyledBaseHeader $isStickyEnabled={isStickyEnabled} $headerMode={headerMode} data-testid="oww-header">
            {isStickyEnabled && !isSecondRowMode && <HeaderScrollObservable ref={setRef} />}
            <SmartAppBanner smartAppBannerConfig={smartAppBannerConfig} />
            <BeforeHeaderWrapper>{beforeHeaderComponent}</BeforeHeaderWrapper>
            <HeaderContentWrapper>
                <HeaderContent data-header-mode={headerMode}>
                    <LogoWrapper>
                        <Logo href={logoHref} linkComponent={logoConfig?.linkComponent} />
                    </LogoWrapper>
                    <TabsWrapper>
                        <Tabs
                            navItems={tabsConfig}
                            onboardingTooltipConfig={onboardingTooltipConfig}
                            isStickyHeaderActive={isStickyHeaderActive}
                        />
                    </TabsWrapper>

                    <LineBreaker />

                    {isMiniMode && miniHeaderConfig && (
                        <BackIconButtonWrapper>
                            <BackIconButton
                                aria-label="Back"
                                data-testid={miniHeaderConfig.backButtonTestId}
                                onClick={miniHeaderConfig.handleBackButtonClick}
                            >
                                <ArrowIcon size={13} />
                            </BackIconButton>
                        </BackIconButtonWrapper>
                    )}

                    {burgerMenuConfig && (
                        <ErrorBoundary fallbackComponent={null}>
                            <BurgerMenuWrapper>
                                <BurgerMenu
                                    onClick={burgerMenuConfig.onClick}
                                    dataTestId={burgerMenuConfig.dataTestId}
                                    className={burgerMenuConfig.className}
                                />
                            </BurgerMenuWrapper>
                        </ErrorBoundary>
                    )}

                    <MiddleWrapper>{middleAreaComponent}</MiddleWrapper>

                    {isMiniMode
                        ? miniHeaderConfig?.isLogoVisible !== false && (
                              <StyledLogoWrapper>
                                  <Logo href={logoHref} linkComponent={logoConfig?.linkComponent} />
                              </StyledLogoWrapper>
                          )
                        : null}

                    <IconTrayWrapper>
                        <IconTray
                            iconTrayVisibility={iconTrayVisibility}
                            iconTrayComponents={
                                isMiniMode && miniHeaderConfig
                                    ? miniHeaderConfig.iconTrayComponents
                                    : iconTrayComponents
                            }
                        />
                    </IconTrayWrapper>

                    {HeaderShadow}
                </HeaderContent>
            </HeaderContentWrapper>
            <AfterHeaderWrapper>{afterHeaderComponent}</AfterHeaderWrapper>
        </StyledBaseHeader>
    );
};
