import * as React from 'react';
import styled, { css } from 'styled-components';
import breakpoints, { forDesktop } from '@westwing/ui-kit/breakPoints';
import { TabsNavItem } from '../types/Navigation';
import { HOTJAR_TRACKING_EVENTS } from '../types/Header';
import { useHeaderContext } from './HeaderContext';
import OnboardingTooltip from './OnboardingTooltip';
import ErrorBoundary from 'Components/ErrorBoundary';
import { OnboardingTooltipConfig } from 'types/OnboardingTooltip';

const spacingVariables = {
    horizontalSpacing: '16px',
    horizontalSpacingSmallScreen: '10px',
};

const StyledTabsList = styled.ul`
    display: flex;
    align-items: center;
    height: 100%;
    margin: 0;

    ${forDesktop`
        gap: 30px;
        height: 25px;
    `}
`;

const upToIphone5 = (...args: Parameters<typeof css>) => css`
    @media all and (max-width: ${breakpoints.breakpointIphone5}) {
        ${css(...args)}
    }
`;

const StyledLink = styled.a`
    font-size: 14px;
    line-height: 19px;
    letter-spacing: 3px;
    text-transform: uppercase;
    text-decoration: none;

    ${forDesktop`
        font-size: 16px;`}
    ${upToIphone5`
        font-size: 12px;
        line-height: 17px;
    `}
`;

const StyledNavItem = styled.li`
    position: relative;
    display: flex;
    align-items: center;
    height: 100%;
    white-space: nowrap;
    margin: 0 ${spacingVariables.horizontalSpacing};

    ${forDesktop`
        margin: 0;
    `}

    ${upToIphone5`
        margin: 0 ${spacingVariables.horizontalSpacingSmallScreen};
    `}

    &:last-child {
        margin-right: 0;
    }

    &:first-child {
        margin-left: 0;
    }

    &,
    ${StyledLink} {
        color: ${({ theme }) => theme.colors.charcoal100};

        &:hover {
            color: ${({ theme }) => theme.colors.turquoise80};
        }
    }

    &:not(:last-of-type) {
        &::after {
            content: '';
            width: 1px;
            background: ${({ theme }) => theme.colors.charcoal30};
            height: 14px;
            right: -${spacingVariables.horizontalSpacing};
            position: absolute;

            ${forDesktop`
                content: none;
            `}

            ${upToIphone5`
                right: -${spacingVariables.horizontalSpacingSmallScreen};
            `}
        }
    }

    &[aria-selected='true'] {
        &,
        ${StyledLink}, ${StyledLink}:hover {
            color: ${({ theme }) => theme.colors.accentPrimary};
            font-family: ${({ theme }) => theme.fonts.fontBrandonMedium};
            font-weight: 600;
        }

        &::before {
            content: '';
            bottom: -1px;
            right: 3px;
            left: 0;
            height: 2px;
            background: currentColor;
            position: absolute;
        }
    }
`;

const StyledNav = styled.nav`
    height: 100%;
    ${forDesktop`
        height: auto;
    `}
`;

export const Tabs = ({
    navItems,
    onboardingTooltipConfig,
    isStickyHeaderActive = false,
}: {
    navItems: TabsNavItem[];
    onboardingTooltipConfig?: OnboardingTooltipConfig;
    isStickyHeaderActive?: boolean;
}): JSX.Element => {
    const {
        trackingHelpers: {
            ga,
            useCurrentPageType,
            hotjar: { trackCustomEvent },
        },
    } = useHeaderContext();
    const { pageType } = useCurrentPageType();

    const getTrackingFunction = (action: string, isSelected: boolean) => {
        const track = isSelected ? ga.trackNonInteractiveGenericEvent : ga.trackInteractiveGenericEvent;

        return () => {
            track('Header', { action, label: `${pageType}` });
            trackCustomEvent(HOTJAR_TRACKING_EVENTS.tabClick);
        };
    };

    return (
        <StyledNav>
            <StyledTabsList role="tablist">
                {navItems.map(navItem => (
                    <StyledNavItem
                        role="tab"
                        key={navItem.linkUrl}
                        aria-selected={navItem.isSelected || undefined}
                        onClick={getTrackingFunction(navItem.trackingAction, navItem.isSelected)}
                    >
                        <StyledLink
                            data-no-overlay={navItem.noOverlay ? 'true' : undefined}
                            href={navItem.linkUrl}
                            data-testid={navItem.testId}
                        >
                            {navItem.linkText}
                        </StyledLink>
                        {!navItem.isSelected && onboardingTooltipConfig?.show && (
                            <ErrorBoundary fallbackComponent={null}>
                                <OnboardingTooltip
                                    tooltipFor={onboardingTooltipConfig.tooltipFor}
                                    headerText={onboardingTooltipConfig.headerText}
                                    text={onboardingTooltipConfig.text}
                                    TooltipIcon={onboardingTooltipConfig.TooltipIcon}
                                    trackingAction={onboardingTooltipConfig.trackingAction}
                                    trackingLabel={onboardingTooltipConfig.trackingLabel}
                                    isStickyHeaderActive={isStickyHeaderActive}
                                />
                            </ErrorBoundary>
                        )}
                    </StyledNavItem>
                ))}
            </StyledTabsList>
        </StyledNav>
    );
};
