import breakpoints, { forDesktop } from '@westwing/ui-kit/breakPoints';
import React from 'react';
import styled from 'styled-components';

export const InfoBlockLink = styled.a`
    text-decoration: none;

    &,
    &:hover,
    &:focus {
        color: currentColor;
    }

    &:focus,
    &:hover {
        text-decoration: underline;
    }
`;

const infoBlockLineHeight = 17;
const infoBlockVerticalPadding = 8;
export const infoBlockHeight = infoBlockLineHeight + infoBlockVerticalPadding * 2;

const infoBlockHeightGlobalCSSVariables = `
    @media (min-width: ${breakpoints.breakpointDesktop}) {
        :root {
            --oww-header-info-block-height: ${infoBlockHeight}px;
        }
    }
`;

const InfoBlockWrapper = styled.div`
    display: flex;
    justify-content: center;
    z-index: ${props => props.theme.zIndexOwwHeaderInfoBlock};
    position: relative;
    background-color: ${props => props.theme.colors.accentSurface};
`;

const InfoBlockBase = styled.div`
    box-sizing: border-box;
    flex: 1;
    width: 100%;
    max-width: calc(${breakpoints.breakpointWideScreens} + 80px);
    padding: ${infoBlockVerticalPadding}px 20px;
    display: none;
    justify-content: flex-end;
    color: ${props => props.theme.colors.charcoal90};
    font-size: 12px;
    line-height: ${infoBlockLineHeight}px;

    & > div {
        padding: 0 20px;

        &:not(:last-child) {
            border-right: 1px solid currentColor;
        }
    }

    ${forDesktop`
        display: flex;
    `}
`;

export interface InfoBlockProps {
    components: React.ReactNode[];
}

const InfoBlock = ({ components }: InfoBlockProps): JSX.Element => (
    <InfoBlockWrapper>
        {/* eslint-disable-next-line react/no-danger */}
        <style dangerouslySetInnerHTML={{ __html: infoBlockHeightGlobalCSSVariables }} />
        <InfoBlockBase>
            {components.map((component, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <div key={index}>{component}</div>
            ))}
        </InfoBlockBase>
    </InfoBlockWrapper>
);

export default InfoBlock;
