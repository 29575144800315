import * as React from 'react';
import styled from 'styled-components';
import { useClubContext } from './hooks/ClubContext';
import { LogoCssProps, StyledOldWestwingLogo, StyledWestwingLogo } from 'Components/Logo';

export const ClubLogoContainer = styled.div<{ active: string }>`
    [data-type] {
        display: none;
    }
    [data-type='${({ active }) => active}'] {
        display: block !important;
    }
`;

export const ClubLogoComponent = (props: LogoCssProps): JSX.Element => {
    const { clubCiVersion } = useClubContext();

    return (
        <ClubLogoContainer active={clubCiVersion}>
            {/* eslint-disable-next-line react/jsx-props-no-spreading */}
            <StyledOldWestwingLogo {...props} data-type="old" />
            {/* eslint-disable-next-line react/jsx-props-no-spreading */}
            <StyledWestwingLogo {...props} data-type="new" />
        </ClubLogoContainer>
    );
};
