import { useEffect, useMemo, useState } from 'react';
import { isTablet } from '@westwing/ui-kit/breakPoints';
import { WWURL } from '../bella/helpers/getSmartAppBannerUrl';
import { SmartAppBannerConfig } from '../types/SmartAppBanner';
import { Cookies, getCookie, setDocumentCookie } from '../utils/cookies';
import { setCookie } from '../api/cookieService';
import {
    SEGMENT_EVENT_FEATURE_INTERACTED,
    SEGMENT_EVENT_FEATURE_VIEWED,
    segmentTrackingProps,
} from '../utils/segmentTrackingProps';
import { useHeaderContext } from 'Components/HeaderContext';

export const SHOP_TRACK_APP_BANNER_CATEGORY = 'App Banner';
export enum ShopTrackAppBannerActions {
    Impression = 'Smart Banner Impression',
    Close = 'Smart App Banner Close',
    Click = 'Smart App Banner Click',
}

export const CLUB_TRACK_APP_BANNER_CATEGORY = 'Header';
export enum ClubTrackAppBannerActions {
    Impression = 'Smart Banner Show',
    Close = 'Smart Banner Close',
    Click = 'Smart Banner Open',
}

export const parseAppBannerCookie = (cookie = ''): { nextCookie: string; frequencyCappingIndicatesShow: boolean } => {
    const [viewCountStr, showAgainTimestampStr] = decodeURIComponent(cookie).split(',');
    const viewCount = Number(viewCountStr);
    const showAgainTimestamp = Number(showAgainTimestampStr);
    const currentTimestamp = Date.now();
    const fiveDaysInTheFuture = currentTimestamp + 86400000 * 5;
    const thirtyDaysInTheFuture = currentTimestamp + 86400000 * 30;

    // first time seeing the banner
    if ([NaN, 0].includes(viewCount) || [NaN, 0].includes(showAgainTimestamp)) {
        return {
            nextCookie: `1,${fiveDaysInTheFuture}`,
            frequencyCappingIndicatesShow: true,
        };
    }

    // second time seeing the banner
    if (viewCount === 1 && showAgainTimestamp < currentTimestamp) {
        return {
            nextCookie: `${viewCount + 1},${fiveDaysInTheFuture}`,
            frequencyCappingIndicatesShow: true,
        };
    }

    // third+ time seeing banner
    if (showAgainTimestamp < currentTimestamp) {
        return {
            nextCookie: `${viewCount + 1},${thirtyDaysInTheFuture}`,
            frequencyCappingIndicatesShow: true,
        };
    }

    return {
        frequencyCappingIndicatesShow: false,
        nextCookie: `1,${fiveDaysInTheFuture}`,
    };
};

const setDismissedCookie = (useDocumentCookie: boolean, isShop: boolean) => {
    const cookie = getCookie(Cookies.APP_BANNER_DISMISSED);
    const { nextCookie } = parseAppBannerCookie(cookie);
    const threeMonthsTtl = 86400000 * 180;
    if (useDocumentCookie || isShop) {
        setDocumentCookie(Cookies.APP_BANNER_DISMISSED, nextCookie, threeMonthsTtl);
    } else {
        setCookie(Cookies.APP_BANNER_DISMISSED, nextCookie, threeMonthsTtl).catch(() => {
            // set cookies to document if Club endpoint is not available
            setDocumentCookie(Cookies.APP_BANNER_DISMISSED, nextCookie, threeMonthsTtl);
        });
    }
};

export const useSmartAppBanner = (
    shouldShowAppBanner: boolean,
    useDocumentCookie: boolean,
    appBannerUrl?: string
): SmartAppBannerConfig => {
    const [isDismissed, setIsDismissed] = useState(false);

    const { trackingHelpers, app } = useHeaderContext();
    const isShop = useMemo(() => app === 'SHOP', [app]);
    const trackingActions = isShop ? ShopTrackAppBannerActions : ClubTrackAppBannerActions;
    const trackingCategory = isShop ? SHOP_TRACK_APP_BANNER_CATEGORY : CLUB_TRACK_APP_BANNER_CATEGORY;

    const { pageType } = trackingHelpers.useCurrentPageType();

    const isShown = useMemo(() => !isDismissed && shouldShowAppBanner, [isDismissed, shouldShowAppBanner]);

    const isClubApp = !!appBannerUrl?.startsWith(WWURL);

    const close = () => {
        trackingHelpers.ga.trackNonInteractiveGenericEvent(trackingCategory, {
            action: trackingActions.Close,
            label: `${pageType}`,
        });
        trackingHelpers.segment.track(SEGMENT_EVENT_FEATURE_INTERACTED, segmentTrackingProps.close);
        setIsDismissed(true);
        setDismissedCookie(useDocumentCookie, isShop);
    };

    const openInApp = () => {
        if (appBannerUrl) {
            trackingHelpers.ga.trackNonInteractiveGenericEvent(trackingCategory, {
                action: trackingActions.Click,
                label: `${pageType}`,
            });
            trackingHelpers.segment.track(SEGMENT_EVENT_FEATURE_INTERACTED, segmentTrackingProps.click);
            window.location.replace(appBannerUrl);
        }
    };

    useEffect(() => {
        if (isShown && !isTablet()) {
            trackingHelpers.ga.trackNonInteractiveGenericEvent(trackingCategory, {
                action: trackingActions.Impression,
                label: `${pageType}`,
            });
            trackingHelpers.segment.track(SEGMENT_EVENT_FEATURE_VIEWED, segmentTrackingProps.view);
        }
        // we need to disable the rule as it requires trackingHelpers.ga to be in deps array.
        // But trackingHelpers.ga keeps updating and triggers event being called several times
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isShown, trackingActions.Impression, trackingCategory]);

    return {
        isShown,
        close,
        openInApp,
        isClubApp,
    };
};
