import * as React from 'react';
import styled from 'styled-components';
import { forDesktop } from '@westwing/ui-kit/breakPoints';

const StyledIconTrayContainer = styled.div<{ $iconTrayVisibility: IconTrayVisibility }>`
    display: flex;
    align-items: center;
    visibility: ${({ $iconTrayVisibility }) => $iconTrayVisibility};
    ${forDesktop`
        gap: 10px;
        margin-top: 4px;
    `}
`;

export const IconTray = ({ iconTrayComponents, iconTrayVisibility }: IconTrayProps): JSX.Element => (
    <StyledIconTrayContainer $iconTrayVisibility={iconTrayVisibility}>{iconTrayComponents}</StyledIconTrayContainer>
);

type IconTrayProps = { iconTrayComponents?: React.ReactNode[]; iconTrayVisibility: IconTrayVisibility };

export type IconTrayVisibility = 'visible' | 'hidden';
