export enum ClubLink {
    CART,
    CART_V2,
    CUSTOMER_ACCOUNT,
    CUSTOMER_ACCOUNT_CREATE_INVITATION,
    CUSTOMER_ACCOUNT_HEADERDATA,
    BFF_CUSTOMER_ACCOUNT_HEADER,
    CUSTOMER_ORDER,
    CUSTOMER_NEWSLETTER,
    CUSTOMER_NOTIFICATIONS,
    HELPCENTER,
    LOGOUT,
    CAMPAIGN,
    CAMPAIGN_UPCOMING,
    CART_REQUEST,
    CUSTOMER_COUPON,
    CART_RESTORE,
    ONE_CART_REQUEST,
    ONE_CART_METADATA,
}

export type ClubLinksType = { [key in ClubLink]: { path: string } };
