/* eslint-disable no-console */
import React from 'react';
import { Logger } from '@westwing/appshell_types/loggerTypes';
import { PageTypeResult } from '@westwing/appshell_types';
import { Theme } from '../theme';
import { HeaderModeType } from '../types/Header';
import { TabsNavItem } from '../types/Navigation';
import { HeaderContextProvider, TrackingHelpers } from 'Components/HeaderContext';
import Header from 'Components/Header';

const useCurrentPageTypeMock = () => ({ pageType: 'homepage' } as PageTypeResult);
const loggerMock = {
    log: console.log,
    debug: console.debug,
    info: console.info,
    warn: console.warn,
    error: console.error,
} as Logger;

const trackingMock: TrackingHelpers = {
    ga: {
        trackNonInteractiveGenericEvent: (category: string, options: { action: string }) => {
            console.error(
                `${
                    'There is no implementation in place for the trackGenericNonInteractiveEvent function' +
                    'category: '
                }${category}action: ${options.action}`
            );
        },
        trackInteractiveGenericEvent: () => {
            console.error('There is no implementation in place for the trackInteractiveGenericEvent function');
        },
    },
    braze: {
        logCustomEvent: () => {
            console.error('There is no implementation in place for the braze logCustomEvent function');
            return Promise.resolve();
        },
    },
    hotjar: {
        trackCustomEvent: () => {
            console.error('There is no implementation in place for the hotjar trackCustomEvent function');
        },
    },
    segment: {
        track: () => {
            console.error('There is no implementation in place for the segment track function');
        },
    },
    useCurrentPageType: useCurrentPageTypeMock,
};

const ErrorBoundaryHeader = ({ tabsConfig }: { tabsConfig: TabsNavItem[] }): JSX.Element | null => (
    <Theme>
        <HeaderContextProvider
            value={{
                app: 'SHOP',
                t: key => key,
                logger: loggerMock,
                trackingHelpers: trackingMock,
                useNewLogo: true,
            }}
        >
            <div data-testid="oww-header-error-boundary">
                <Header
                    shouldShowAppBanner={false}
                    headerMode={HeaderModeType.FIRST_ROW}
                    tabsConfig={tabsConfig}
                    isStickyEnabled={false}
                    logoHref={tabsConfig[0].linkUrl}
                />
            </div>
        </HeaderContextProvider>
    </Theme>
);

export default ErrorBoundaryHeader;
